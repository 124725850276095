// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDgj6EG906imqTvUyPXf-_zQDpawK5Gtgg',
    authDomain: 'wgbn-financial-manager.firebaseapp.com',
    databaseURL: 'https://wgbn-financial-manager.firebaseio.com',
    projectId: 'wgbn-financial-manager',
    storageBucket: 'wgbn-financial-manager.appspot.com',
    messagingSenderId: '1071771668557',
    appId: '1:1071771668557:web:603c6e2015f4134d128c04'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
