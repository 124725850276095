import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

const chunkArray = (array, chunk) => {
    let tempArray = [];
    for (let i = 0; i < array.length; i += chunk) {
        tempArray = [...tempArray, array.slice(i, i + chunk)];
    }
    return tempArray;
};

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    private project: any = null;
    private provider: any = null;
    private token: string = null;
    private user: any = null;

    authUser: Observable<any>;

    constructor(private location: Location, private router: Router) {
        console.log('# FirebaseService');
        this.getLocalUser();

        this.authUser = new Observable<any>(obs => {
            firebase.auth().onAuthStateChanged((user: any) => obs.next(user));
        });
    }

    public init() {
        this.project = firebase.initializeApp(environment.firebaseConfig);
        if (this.project) {
            console.log(this.project.name);
            this.provider = new firebase.auth.GoogleAuthProvider();
            this.verifyLogged();
        }
    }

    private getLocalUser() {
        const local = sessionStorage.getItem('___u') || null;
        if (local !== null) {
            this.user = JSON.parse(local);
        }
    }

    private verifyLogged() {
        console.log('# verifyLogged');
        firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
                this.user = user;
                if (this.location.path().indexOf('login') > -1) {
                    this.router.navigate(['/']);
                }
            } else {
                // User is signed out.
                this.user = null;
            }
        });
    }

    private createUser(user: any) {
        const displayName = user.displayName;
        const email = user.email;
        const photoURL = user.photoURL;
        const uid = user.uid;

        firebase.firestore().collection('users').doc(uid).get().then((doc: any) => {
            console.log('# user', doc.exists);
            if (!doc.exists) {
                firebase.firestore().collection('users').doc(uid).set({
                    displayName,
                    email,
                    photoURL
                });
            }
        });
    }

    public getLoggedUser() {
        return this.user;
    }

    public login(): Promise<any> {
        return firebase.auth().signInWithPopup(this.provider).then((result: any) => {
            console.log(result);
            // This gives you a Google Access Token. You can use it to access the Google API.
            this.token = result.credential.accessToken;
            // The signed-in user info.
            this.user = result.user;
            // ...
            this.createUser(result.user);
            sessionStorage.setItem('___u', JSON.stringify(this.user));
        }).catch((error: any) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            const credential = error.credential;
            // ...
            sessionStorage.clear();
        });
    }

    public logout() {
        firebase.auth().signOut().then(() => {
            // Sign-out successful.
            sessionStorage.clear();
            this.router.navigate(['/login']);
        }).catch((error) => {
            // An error happened.
        });
    }

    public create(resource: string, value: any, id = null): Promise<unknown> {
        const timestamp = new Date().getTime();
        value = clearUndefined({
            ...value,
            uid: this.user.uid,
            createdAt: value.createdAt || timestamp,
            updatedAt: timestamp,
        });
        return id !== null ? firebase.firestore().collection(resource).doc(id).set(value, {merge: true})
            : firebase.firestore().collection(resource).add(value);
    }

    public getOnce(resource: string, id: string): Promise<any> {
        return firebase.firestore().collection(resource).doc(id).get().then(doc => ({...doc.data(), id: doc.id}));
    }

    public get(resource: string, id: string): Observable<any> {
        return new Observable<any>(obs => {
            firebase.firestore().collection(resource).doc(id).onSnapshot(snap => obs.next({...snap.data(), id: snap.id}));
        });
    }

    public listOnce_(resource: string, where: Array<any[]> = [], limit = 0) {
        const query = firebase.firestore().collection(resource);
        if (where.length) {
            for (const wh of where) {
                query.where(wh[0], wh[1], wh[2]);
            }
        }
        if (limit > 0) {
            query.limit(limit);
        }
        return query.get().then(valuesWithId);
    }

    public listOnce(resource: string, where: Array<any[]> = [], args: any = {limit: 0}): Promise<any> {
        const queryRef = firebase.firestore().collection(resource);
        let query: any = queryRef;
        if (where.length) {
            for (const wh of where) {
                query = query.where(wh[0], wh[1], wh[2]);
            }
        }
        if (args.hasOwnProperty('limit') && args.limit > 0) {
            query = query.limit(args.limit);
        }
        if (args.hasOwnProperty('sort')) {
            let sort = [];
            if (!(args.sort instanceof Array)) sort.push(args.sort);
            else sort = args.sort;

            for (const s of sort) {
                const split = s.split(' ');
                query = query.orderBy(split[0], split[1] ? split[1] : 'asc');
            }
        }
        // console.log(query);
        return query.get().then(values => valuesWithId(values));
    }

    public list(resource: string, where: Array<any[]> = [], args: any = {limit: 0}): Observable<any> {
        const queryRef = firebase.firestore().collection(resource);
        let query: any = queryRef;
        if (where.length) {
            for (const wh of where) {
                query = query.where(wh[0], wh[1], wh[2]);
            }
        }
        if (args.hasOwnProperty('limit') && args.limit > 0) {
            query = query.limit(args.limit);
        }
        if (args.hasOwnProperty('sort')) {
            const sort = args.sort.split(' ');
            query = query.orderBy(sort[0], sort[1] ? sort[1] : 'asc');
        }
        // console.log(query);
        return new Observable<any[]>(obs => {
            query.onSnapshot(snapshot => obs.next(valuesWithId(snapshot)));
        });
    }

    public remove(resource: string, item: any): Promise<any> {
        return firebase.firestore().collection(resource).doc(item.id).delete();
    }

    public update(resource: string, item: any): Promise<any> {
        item.updatedAt = new Date().getTime();
        return firebase.firestore().collection(resource).doc(item.id).set(clearUndefined(item), {merge: true});
    }

    public batch(resource: string, items: any[], action = 'create'): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (items.length > 499) {
                const splited = chunkArray(items, 499);

                for (const [i, spl] of splited.entries()) {
                    const batch = firebase.firestore().batch();
                    for (const doc of spl) {
                        if (doc) {
                            const id = doc.id || firebase.firestore().collection('_').doc().id;
                            // console.log('create', id);
                            const ref = firebase.firestore().collection(resource).doc(id);
                            if (action === 'create') {
                                batch.set(ref, {...doc, uid: this.user.uid});
                            }
                            if (action === 'update') {
                                batch.update(ref, {...doc, uid: this.user.uid});
                            }
                            if (action === 'delete') {
                                batch.delete(ref);
                            }
                        }
                    }
                    try {
                        await batch.commit();
                        resolve(true);
                        console.log(resource, items.length, i);
                    } catch (e) {
                        console.log('# ERRO:', e.message);
                        reject(e.message);
                    }
                }
            } else {
                const batch = firebase.firestore().batch();
                for (const spl of items) {
                    if (spl) {
                        const id = spl.id || firebase.firestore().collection('_').doc().id;
                        // console.log('create', id);
                        const ref = firebase.firestore().collection(resource).doc(id);
                        if (action === 'create') {
                            batch.set(ref, {...spl, uid: this.user.uid});
                        }
                        if (action === 'update') {
                            batch.update(ref, {...spl, uid: this.user.uid});
                        }
                        if (action === 'delete') {
                            batch.delete(ref);
                        }
                    }
                }
                try {
                    await batch.commit();
                    resolve(true);
                    console.log(resource, items.length);
                } catch (e) {
                    console.log('# ERRO:', e.message);
                    reject(e.message);
                }
            }
        });
    }

    public createId(): string {
        return firebase.firestore().collection('_').doc().id;
    }
}

const clearUndefined = obj => {
    for (const prop in obj) {
        if (obj[prop] === undefined) {
            delete obj[prop];
        }
    }
    return obj;
};

const valuesWithId = snapshot => {
    const values = [];
    snapshot.forEach(item => values.push({id: item.id, ...item.data()}));
    return values;
};
// "@angular-devkit/architect": "~0.1301.1",
// "@angular-devkit/build-angular": "~13.1.1",
// "@angular-devkit/core": "~13.1.1",
// "@angular-devkit/schematics": "~13.1.1",
