import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './@shared/services/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'export', loadChildren: () => import('./@pages/export/export.module').then(m => m.ExportModule) },
  // { path: 'dashboard/:categoryID/:date', canActivate: [AuthGuard],
  //   loadChildren: () => import('./@pages/dashboard/dashboard-detail/dashboard-detail.module').then( m => m.DashboardDetailPageModule) },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
